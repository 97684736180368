

export const OutputCard = (props: any) => {
    if(props.text === ""){
        return (
            <>
            </>
        )
    } 
    else{
    return(
        <div role="listitem" style={{width: '1000px', margin: 'auto'}} className="bg-gray-900 cursor-pointer shadow rounded-lg mt-3 p-2 flex relative z-30">
        <div className="w-2.5 h-auto bg-yellow-700 rounded-tl-md  rounded-bl-md" />
        <div className="w-full p-8"><div className="md:flex items-center justify-between">
        <h2 className="text-2xl font-semibold leading-6 text-gray-50 leading-relaxed"  dangerouslySetInnerHTML={{
    __html: props.text
  }}>
           
        </h2>
        </div>
        </div>
        </div>
    )
    }
}